
/*light background color #99b19b; */
/*dark background color #506d4e; */
/*border color #2d714c */
/* name plate background color #cad4cc; */

.App {
  text-align: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  background-color: #eeeeee;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Card {
  width: 75%;
  max-width: 650px;
  height: 75%;
  border: black solid;
  border-width: .5rem .5rem 2rem .5rem ;
  border-radius: 25px;

  padding: 1rem 2rem 0rem 2rem;
  margin: auto;


  /* Background */
  /* from: https://css-pattern.com/curvy/ */
    --s: 22px; /* control the size*/
    --c1: #506d4e;
    --c2: #99b19b;
    
    --_s: calc(2*var(--s)) calc(2*var(--s));
    --_g: 35.36% 35.36% at;
    --_c: #0000 66%,var(--c2) 68% 70%,#0000 72%;
    background:
      radial-gradient(var(--_g) 100% 25%,var(--_c)) var(--s) var(--s)/var(--_s),
      radial-gradient(var(--_g) 0    75%,var(--_c)) var(--s) var(--s)/var(--_s),
      radial-gradient(var(--_g) 100% 25%,var(--_c)) 0 0/var(--_s),
      radial-gradient(var(--_g) 0    75%,var(--_c)) 0 0/var(--_s),
      repeating-conic-gradient(var(--c1) 0 25%,#0000 0 50%) 0 0/var(--_s),
      radial-gradient(var(--_c)) 0 calc(var(--s)/2)/var(--s) var(--s)
      var(--c1);
  
}

.LineEnd {
  align-self: end;
  margin-left: auto;
}

/* .Border */
h1, .BodyText, .ImageFrame {
  border: #2d714c 3px solid;
}

/* .BoxShadow  */
h1, .BodyText, .ImageFrame {
  box-shadow: -4px 0px rgba(0,0,0,.5),
  4px 0px rgba(255,255,255,.25);
}

h1 {
  text-align: left;

  border-radius: 1rem / 50%;
  margin: 0 -1rem;
  padding: 3px 1rem;

  background: #cad4cc;

  outline: black .5px solid;
  outline-offset: -3px;

  display: flex;
}

.BodyText {
  background: rgba(255,255,255,.75);
}

.ImageFrame {
  height: 15rem;
  /* width: 75%; */
}

.CardPicture {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border: black 1.5px solid;
}

hr {
  /* height: 1px; */
  width: 85%;

  /* color: black; */
  border: solid 1px grey;
  background-color: grey;
  /* box-shadow: -1px -1px rgba(0,0,0,1); */
  box-shadow: 0 0 10px 1px grey;
  
}